var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body"
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/reset-password.png"),
                        alt: "login"
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-8" },
                      [
                        _c("div", { staticClass: "vx-card__title mb-8" }, [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v("Reset Password")
                          ]),
                          _c("p", [_vm._v("Please enter your new password.")])
                        ]),
                        _c("vs-input", {
                          staticClass: "w-full mb-6",
                          attrs: {
                            type: "email",
                            "label-placeholder": "Email"
                          },
                          model: {
                            value: _vm.value1,
                            callback: function($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1"
                          }
                        }),
                        _c("vs-input", {
                          staticClass: "w-full mb-6",
                          attrs: {
                            type: "password",
                            "label-placeholder": "Password"
                          },
                          model: {
                            value: _vm.value2,
                            callback: function($$v) {
                              _vm.value2 = $$v
                            },
                            expression: "value2"
                          }
                        }),
                        _c("vs-input", {
                          staticClass: "w-full mb-8",
                          attrs: {
                            type: "password",
                            "label-placeholder": "Confirm Password"
                          },
                          model: {
                            value: _vm.value3,
                            callback: function($$v) {
                              _vm.value3 = $$v
                            },
                            expression: "value3"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap justify-between flex-col-reverse sm:flex-row"
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto",
                                attrs: { type: "border", to: "/pages/login" }
                              },
                              [_vm._v("Go Back To Login")]
                            ),
                            _c(
                              "vs-button",
                              { staticClass: "w-full sm:w-auto" },
                              [_vm._v("Reset")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }